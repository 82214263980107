<template>
	<div class="d-flex page-wrapper flex-column h-100 justify" :class="{ 'onSidebarOpen': sidebarVisible }">
		<div class="m-auto card-receipt bg-white d-flex flex-column align-items-center" id="cardReceipt">
			<img class="ihs-logo" src="@/assets/logo/ihs-logo-bw.svg" alt="">
			<p class="font-12 text-center px-2 mt-3 mb-1">Jl. Sederhana No.63, Pasteur, Kec. Sukajadi, Kota Bandung, Jawa
				Barat 40161</p>
			<div class="d-flex flex-column">
				<span class="text-center font-12">(022) 2033144</span>
				<span class="text-center font-12">Struk Pengembalian</span>
				<span class="text-center font-12">{{ returnedOrder.invoice_order }}</span>
			</div>
			<div class="cashier-container w-100 my-2 py-2 font-12">
				<div class=" d-flex justify-content-between">
					<span>No Invoice </span>
					<span>{{ returnedOrder.return_invoice_number }}</span>
				</div>
				<div class=" d-flex justify-content-between">
					<span>Kasir: {{ cashierName }} </span>
					<span>{{ returnedOrder.created_at }}</span>
				</div>
			</div>
			<div class="product font-12 align-self-start align-items-center w-100" v-for="product in returnedOrder.products"
				:key="product.id">
				<p class="product-name mb-0">{{ product.name }}</p>
				<div class="ps-3 d-flex justify-content-between w-100">
					<span> (-{{ product.quantity }}) x {{ formatRupiah(product.discounted_price, 'Rp. ') }} </span>
					<span> -{{ formatRupiah(product.quantity * product.discounted_price, 'Rp.') }}</span>
				</div>
			</div>
			<div class="total-container d-flex flex-column pt-2 w-100 mt-3">
				<div class="d-flex justify-content-between">
					<span class="font-18 font-500">Total</span>
					<span class="font-18 font-500">-{{ formatRupiah(returnedOrder.payment.total_payment, 'Rp.') }}</span>
				</div>
			</div>

			<span class="payment-divider w-100 text-center overflow-hidden font-14 mt-1">Payment</span>
			<div class="payment-detail d-flex flex-column align-self-stretch">
				<div class="d-flex font-12">
					<span>Pengembalian Tunai</span>
					<span class="ms-auto">
						{{ formatRupiah(Math.abs(returnedOrder.payment.total_payment), 'Rp.') }}
					</span>
				</div>
			</div>

			<div class="receipt-footer d-flex flex-column align-self-stretch mt-2 py-3">
				<span class="text-center font-12">HARGA SUDAH TERMASUK PAJAK</span>
				<span class="text-center font-12 mb-3">Terima Kasih telah berbelanja di <br> Inti Health Store</span>
				<p class="text-center font-12">
					Barang yang sudah dibeli tidak bisa ditukar / dikembalikan
				</p>
			</div>

		</div>
		<div class="footer mt-auto d-flex fixed-bottom bg-white py-3 gap-2">
			<div class="flex-grow-1 pl-3 me-2 d-flex align-items-center">
				<span class="ms-3">
					Pengembalian Tunai
				</span>
				<span class="font nunito font-20 font-500 text-primary ms-3">
					{{ formatRupiah(Math.abs(returnedOrder.payment.total_payment), 'Rp.') }}
				</span>
			</div>
			<button class="button button--primary me-4 px-5" v-print="'#cardReceipt'">
				<img src="@/assets/icons/print.svg" alt="print icon" class="me-1">
				Print Struk
			</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IhsMixin from "@/mixins/ihs.mixins.js";
import print from "vue-print-nb";

export default {
	mixins: [IhsMixin],
	directives: {
		print
	},
	computed: {
		...mapState("home", ["sidebarVisible"]),
		...mapState("returns", ["returnedOrder"]),
		cashierName() {
			return JSON.parse(localStorage.activeCashier).name;
		},
		returnTotal() {
			let total = 0
			this.returnedOrder.products.forEach(product => {
				total += product.quantity * product.discounted_price
			})

			return total
		}

	},
	methods: {
		...mapMutations("home", ["setSidebarVisible"])
	},
	created() {
		this.setSidebarVisible(false);
	}
};
</script>

<style lang="scss">
@media print {
	.ihs-logo {
		width: 109px !important;
		height: 40px !important;
		object-fit: cover !important;
	}

	.font-14,
	.font-12 {
		font-size: 12px !important;
	}

	.font-18 {
		font-size: 14px !important;
	}

	.navbar {
		display: none !important;
	}

	.footer {
		display: none !important;
	}

	.card-receipt {
		width: 56mm !important;
		border: 0 !important;
		padding: 10px 0 !important;
		border-radius: 0 !important;
		margin: 0 !important;
	}

	html {
		padding-top: 10x !important;
		padding-bottom: 10px !important;
		margin-bottom: 10px !important;
	}

	.page-wrapper {
		padding: 0 !important;
	}

}
</style>
<style lang="scss" scoped>
.card-receipt {
	padding: 30px;
	width: 315px;
	border: 0.5px solid #cdcdce;
	border-radius: 8px;
}

.cashier-container {
	border-top: 1px dashed black;
	border-bottom: 1px dashed black;
}

.total-container {
	border-top: 1px dashed black;
}

.product-name {
	width: 60%;
}

.receipt-footer {
	border-top: 1px dashed black;
	border-bottom: 2px dashed black;
}

.payment-divider:before,
.payment-divider:after {
	border-top: 1px dashed black;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.payment-divider:before {
	right: 0.5em;
	margin-left: -50%;
}

.payment-divider:after {
	left: 0.5em;
	margin-right: -50%;
}

.page-wrapper {
	background: #f5f5f5;
	transition: margin 400ms ease-in-out;
	overflow-x: hidden;
	position: relative;
	padding-top: 24px;
	padding-bottom: 100px;

	&.onSidebarOpen {
		margin-left: 78px;
	}
}

.return-price-container {
	border-right: 1px solid #E0E0E0;
	padding-right: 20px;
}
</style>

